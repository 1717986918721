import { Page, Content } from '@backstage/core-components';
import {
  HomePageStarredEntities,
  HomePageTopVisited,
  HomePageRecentlyVisited,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { NewAnnouncementBanner, AnnouncementsCard } from '@procore-oss/backstage-plugin-announcements';
import LogoFull from '../Root/LogoFull';
import LogoIcon from '../Root/LogoIcon';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

export const HomePage = () => {
  const classes = useStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <NewAnnouncementBanner />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" spacing={2}>
            <Grid container item xs={12} justifyContent="center" style={{ textAlign: 'center' }}>
              <Grid item xs={10} md={4}>
                <LogoIcon  />
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="center" style={{ textAlign: 'center' }}>
              <Grid item xs={10} md={4}>
                <LogoFull />
              </Grid>
            </Grid>
          </Grid>
					<Grid container justifyContent="center" spacing={2}>
						<Grid container item xs={12} justifyContent="center">
							<HomePageSearchBar
									InputProps={{
									classes: {
											root: classes.searchBarInput,
											notchedOutline: classes.searchBarOutline,
									},
									}}
									placeholder="Search"
							/>
						</Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
              	<AnnouncementsCard max={3} />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageTopVisited />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageRecentlyVisited />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};